<template>
  <div>
    <div class="buy">
      <div class="buy1">
        <div class="left" @click="show = true">
          <div>
            <p>{{ $t('contract').sl }}</p>
            <p>{{ Amount }}</p>
          </div>
          <div>
            <p>{{ $t('contract').sj }}</p>
            <p>{{ buyModeList[activeIndex].Time }}</p>
          </div>
          <div>
            <p>{{ $t('contract').yll }}</p>
            <p>{{ buyModeList[activeIndex].Win * 100 }}%</p>
          </div>
          <van-icon
            name="arrow-down"
            color="#8e1473"
            size="18"
            style="margin: 0 2px"
          />
        </div>
        <div
          class="btn"
          :style="{
            background: basicConfig.RiseColour
          }"
          @click="handleBuy('UP')"
        >
          {{ $t('contract').mz }}
        </div>
        <div
          class="btn"
          :style="{
            background: basicConfig.FallColour
          }"
          @click="handleBuy('Down')"
        >
          {{ $t('contract').md }}
        </div>
      </div>
    </div>
    <div v-show="show" class="mask" @click="handleCancle">
      <div class="selectBox" @click.stop="" v-if="!buyMode">
        <div class="content">
          <!-- 开仓数量 -->
          <div class="title">{{ $t('contract').kcsl }}</div>
          <div class="input">
            <van-field v-model="Amount" placeholder="Other quantity" />
          </div>
          <!-- 开仓时间 -->
          <div class="title">{{ $t('contract').kcsj }}</div>
          <div class="timeList">
            <div
              class="item"
              v-for="(item, index) in buyModeList"
              :key="index"
              :class="{ active: activeIndex === index }"
              @click="activeIndex = index"
            >
              {{ item.Time }}
            </div>
          </div>
          <!-- 可用余额 -->
          <div class="balanceInfo">
            <div>
              {{ $t('contract').zhye }}：<span class="down"
                >{{ USDT }} USDT</span
              >
            </div>
            <div>
              {{ $t('contract').yll }}：<span class="down rodia"
                >{{ buyModeList[activeIndex].Win * 100 }}%</span
              >
            </div>
          </div>
        </div>
        <div class="btnbox">
          <div
            class="btn"
            @click="handleBuy('UP')"
            :style="{
              background: basicConfig.RiseColour
            }"
          >
            {{ $t('contract').mz }}
          </div>
          <div
            class="btn"
            @click="handleBuy('Down')"
            :style="{
              background: basicConfig.FallColour
            }"
          >
            {{ $t('contract').md }}
          </div>
        </div>
      </div>
      <div class="buyBox" @click.stop="" v-else>
        <div
          class="top"
          :style="{
            background:
              mode !== 'UP' ? basicConfig.FallColour : basicConfig.RiseColour
          }"
        >
          <span>
            {{ mode === 'UP' ? $t('contract').mz : $t('contract').md }}
          </span>
          <van-icon name="cross" @click="handleCancle" />
        </div>
        <div class="middle">
          <div>
            <span>{{ $t('contract').sl }}：</span> {{ Amount }}
            USDT
          </div>
          <div>
            <span>{{ $t('contract').sj }}：</span>
            {{ buyModeList[activeIndex].Time }}
          </div>
          <div>
            <span>{{ $t('contract').yll }}：</span>
            {{ buyModeList[activeIndex].Win * 100 }} %
          </div>
        </div>
        <div class="btn-box">
          <div class="btn" @click="handleCancle">
            {{ $t('contract').qc }}
          </div>
          <div
            class="btn "
            :style="{
              background: basicConfig.RiseColour
            }"
            v-if="mode === 'UP'"
            @click="handleCreateOrder('Rise')"
          >
            {{ $t('contract').mz }}
          </div>
          <div
            class="btn "
            :style="{
              background: basicConfig.FallColour
            }"
            v-else
            @click="handleCreateOrder('Fall')"
          >
            {{ $t('contract').md }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productApi from '@/api/product'
import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
export default {
  props: {
    ProductInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    buyModeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  data() {
    return {
      show: false,
      Amount: 0,
      activeIndex: 0,
      buyMode: false,
      mode: true,
      USDT: 0.0
    }
  },
  watch: {
    ProductInfo(value) {
      this.Amount = value.BuyLow
    },
    buyModeList() {
      this.activeIndex = 0
    }
  },
  mounted() {
    this.Amount = this.ProductInfo.BuyLow
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const res = await userApi.userInfo()
      this.USDT = res.LegalAmountInfo.USDT
    },
    handleBuy(mode) {
      this.show = true
      this.buyMode = true
      this.mode = mode
    },
    handleCancle() {
      this.show = false
      this.buyMode = false
    },
    async handleCreateOrder(Direction) {
      let obj = {
        ProductId: this.ProductInfo.Id,
        Direction,
        TimeId: this.buyModeList[this.activeIndex].Id,
        Amount: Number(this.Amount)
      }
      if (obj.Amount === 0) {
        this.$toast({
          position: 'bottom',
          message: this.$t('contract').placeholder
        })
        return
      }
      if (
        obj.Amount <= this.ProductInfo.Proportion[this.activeIndex].LowLimit ||
        obj.Amount >= this.ProductInfo.Proportion[this.activeIndex].HighLimit
      ) {
        this.$toast({
          position: 'center',
          message: `${this.$t('contract').kcslfw}${
            this.ProductInfo.Proportion[this.activeIndex].LowLimit
          } - ${this.ProductInfo.Proportion[this.activeIndex].HighLimit}`
        })
        return
      }
      await productApi.createOrder(obj)
      this.handleCancle()
      this.$toast.success(this.$t('contract').SuccessfulPurchase)
      EventBus.$emit('createOrder')
    }
  }
}
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.buyBox {
  height: 213px;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 3px;
  background: #202833;
  .top {
    display: flex;
    padding: 0 10px;
    align-items: center;
    height: 42px;
    font-size: 14px;
    justify-content: space-between;
    color: #fff;
    background: #4f986f;
    border-bottom: 1px solid #eee;
  }
  .middle {
    text-align: center;
    padding: 15px 10px;
    span {
      width: 34%;
      text-align: right;
      display: inline-block;
    }
    div {
      font-size: 14px;
      margin-top: 12px;
      text-align: left;
    }
  }
  .btn-box {
    padding: 10px 15px 0;
    display: flex;
    justify-content: space-between;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      font-size: 16px;
      background-color: #1e9fff;
      color: #fff;
      width: 146px;
      border-radius: 3px;
    }
    .green {
      background: #4f986f;
    }
  }
}
.red {
  background: #c15a58 !important;
}
.selectBox {
  background: #202833;
  position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  bottom: 50px;
  bottom: calc(50px + env(safe-area-inset-bottom));

  width: 100vw;
  font-size: 12px;
  .content {
    padding: 15px;
    .title {
      margin: 10px 0;
    }
    .input {
      width: 152px;
      border: 1px solid #12151c;
      background-color: #202833;
      .van-field {
        background: transparent;
        padding: 3px 16px;
        & /deep/ .van-field__control {
          color: #fff;
          text-align: center;
        }
      }
    }
    .timeList {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 48%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #12151c;
        margin-bottom: 10px;
        // margin: 0 5px;
        font-size: 12px;
        text-align: center;
        // &:nth-of-type(1) {
        //   margin-left: 0;
        // }
      }
      .active {
        background: url('~@/assets/new/selects.png') right bottom no-repeat;
        background-size: auto 100%;
      }
    }
    .balanceInfo {
      margin-top: 10px;
      font-size: 14px;

      display: flex;
      justify-content: space-between;
      span {
        font-weight: bold;
      }
      .rodia {
        font-size: 20px;
      }
    }
  }
  .btnbox {
    height: 44px;
    // padding: 0 2px;
    display: flex;
    justify-content: space-between;
    .btn {
      display: flex;
      width: 180px;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      border-radius: 3px;
    }
  }
}
.buy {
  position: fixed;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  width: 100vw;
  bottom: 50px;

  .buy1 {
    display: flex;
    align-items: center;
    background: #152741;
    height: 70px;
  }
  .left {
    display: flex;
    align-items: center;
    width: 195px;
    height: 50px;
    border: 1px solid #12151c;
    margin: 0 5px;
    border-radius: 3px;
    div {
      flex: 1;
      text-align: center;
      line-height: 1.5;
    }
  }
  .btn {
    font-size: 16px;
    height: 50px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    color: #ffffff;
    border-radius: 5px;
  }
}
</style>
