var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"buy"},[_c('div',{staticClass:"buy1"},[_c('div',{staticClass:"left",on:{"click":function($event){_vm.show = true}}},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('contract').sl))]),_c('p',[_vm._v(_vm._s(_vm.Amount))])]),_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('contract').sj))]),_c('p',[_vm._v(_vm._s(_vm.buyModeList[_vm.activeIndex].Time))])]),_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('contract').yll))]),_c('p',[_vm._v(_vm._s(_vm.buyModeList[_vm.activeIndex].Win * 100)+"%")])]),_c('van-icon',{staticStyle:{"margin":"0 2px"},attrs:{"name":"arrow-down","color":"#8e1473","size":"18"}})],1),_c('div',{staticClass:"btn",style:({
          background: _vm.basicConfig.RiseColour
        }),on:{"click":function($event){return _vm.handleBuy('UP')}}},[_vm._v(" "+_vm._s(_vm.$t('contract').mz)+" ")]),_c('div',{staticClass:"btn",style:({
          background: _vm.basicConfig.FallColour
        }),on:{"click":function($event){return _vm.handleBuy('Down')}}},[_vm._v(" "+_vm._s(_vm.$t('contract').md)+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"mask",on:{"click":_vm.handleCancle}},[(!_vm.buyMode)?_c('div',{staticClass:"selectBox",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('contract').kcsl))]),_c('div',{staticClass:"input"},[_c('van-field',{attrs:{"placeholder":"Other quantity"},model:{value:(_vm.Amount),callback:function ($$v) {_vm.Amount=$$v},expression:"Amount"}})],1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('contract').kcsj))]),_c('div',{staticClass:"timeList"},_vm._l((_vm.buyModeList),function(item,index){return _c('div',{key:index,staticClass:"item",class:{ active: _vm.activeIndex === index },on:{"click":function($event){_vm.activeIndex = index}}},[_vm._v(" "+_vm._s(item.Time)+" ")])}),0),_c('div',{staticClass:"balanceInfo"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('contract').zhye)+"："),_c('span',{staticClass:"down"},[_vm._v(_vm._s(_vm.USDT)+" USDT")])]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('contract').yll)+"："),_c('span',{staticClass:"down rodia"},[_vm._v(_vm._s(_vm.buyModeList[_vm.activeIndex].Win * 100)+"%")])])])]),_c('div',{staticClass:"btnbox"},[_c('div',{staticClass:"btn",style:({
            background: _vm.basicConfig.RiseColour
          }),on:{"click":function($event){return _vm.handleBuy('UP')}}},[_vm._v(" "+_vm._s(_vm.$t('contract').mz)+" ")]),_c('div',{staticClass:"btn",style:({
            background: _vm.basicConfig.FallColour
          }),on:{"click":function($event){return _vm.handleBuy('Down')}}},[_vm._v(" "+_vm._s(_vm.$t('contract').md)+" ")])])]):_c('div',{staticClass:"buyBox",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"top",style:({
          background:
            _vm.mode !== 'UP' ? _vm.basicConfig.FallColour : _vm.basicConfig.RiseColour
        })},[_c('span',[_vm._v(" "+_vm._s(_vm.mode === 'UP' ? _vm.$t('contract').mz : _vm.$t('contract').md)+" ")]),_c('van-icon',{attrs:{"name":"cross"},on:{"click":_vm.handleCancle}})],1),_c('div',{staticClass:"middle"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('contract').sl)+"：")]),_vm._v(" "+_vm._s(_vm.Amount)+" USDT ")]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('contract').sj)+"：")]),_vm._v(" "+_vm._s(_vm.buyModeList[_vm.activeIndex].Time)+" ")]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('contract').yll)+"：")]),_vm._v(" "+_vm._s(_vm.buyModeList[_vm.activeIndex].Win * 100)+" % ")])]),_c('div',{staticClass:"btn-box"},[_c('div',{staticClass:"btn",on:{"click":_vm.handleCancle}},[_vm._v(" "+_vm._s(_vm.$t('contract').qc)+" ")]),(_vm.mode === 'UP')?_c('div',{staticClass:"btn",style:({
            background: _vm.basicConfig.RiseColour
          }),on:{"click":function($event){return _vm.handleCreateOrder('Rise')}}},[_vm._v(" "+_vm._s(_vm.$t('contract').mz)+" ")]):_c('div',{staticClass:"btn",style:({
            background: _vm.basicConfig.FallColour
          }),on:{"click":function($event){return _vm.handleCreateOrder('Fall')}}},[_vm._v(" "+_vm._s(_vm.$t('contract').md)+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }