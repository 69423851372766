var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('header',[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("@/assets/new/lists.png"),"alt":""},on:{"click":function($event){_vm.show = true}}}),_c('span',{on:{"click":function($event){_vm.show = true}}},[_vm._v(_vm._s(_vm.showProductName())+"/USDT")])]),_c('div',{staticClass:"right"},[(_vm.productNowInfo[_vm.activeIndex].IsContract)?_c('img',{attrs:{"src":require("@/assets/new/contract.png"),"alt":""},on:{"click":_vm.goContract}}):_vm._e(),(_vm.productNowInfo[_vm.activeIndex].IsCoin)?_c('img',{attrs:{"src":require("@/assets/new/trade1.png"),"alt":""},on:{"click":_vm.goCoin}}):_vm._e()])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left down",style:({
        color:
          _vm.productNowInfo[_vm.activeIndex].BasicPrice -
            _vm.productNowInfo[_vm.activeIndex].Price >
          0
            ? _vm.basicConfig.FallColour
            : _vm.basicConfig.RiseColour
      })},[_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Price)+" ")]),_c('div',{staticClass:"radio"},[_vm._v(_vm._s(_vm.getRole())+"%")])]),_c('div',{staticClass:"right"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('contract').gao))]),_c('span',[_vm._v(_vm._s(_vm.productNowInfo[_vm.activeIndex].High))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('contract').di))]),_c('span',[_vm._v(_vm._s(_vm.productNowInfo[_vm.activeIndex].Low))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('contract').liang))]),_c('span',[_vm._v(_vm._s(_vm.productNowInfo[_vm.activeIndex].Amount))])])])]),_c('div',{staticClass:"echar-box"},[_c('TradingView',{ref:"tra",attrs:{"TvCode":_vm.select_tv_code,"Height":"340px"}})],1),_c('List',{ref:"orderList",attrs:{"productInfo":_vm.productNowInfo[_vm.activeIndex]}}),_c('Buy',{attrs:{"buyModeList":_vm.buyModeList,"ProductInfo":_vm.ProductInfo}}),_c('TabBar',{attrs:{"active":_vm.active}}),_c('van-popup',{style:({ width: '244px', height: 'calc(100vh - 100px )' }),attrs:{"position":"left"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('PopupInfo',{attrs:{"productNowInfo":_vm.productNowInfo},on:{"change":_vm.changeProduct}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }