<!--
 * @Description: 
 * @Author: 爱酱丶
 * @Date: 2022-11-17 18:06:48
 * @LastEditTime: 2022-12-01 15:12:15
 * @LastEditors: 爱酱丶
-->
<template>
  <div class="leftInfo">
    <div class="title">
      {{ $t('contract').mhy }}
    </div>
    <div class="usdt">
      usdt
    </div>
    <div class="scroll">
      <div
        class="item"
        v-for="(item, index) in productNowInfo"
        :key="index"
        @click="handleClick(index)"
      >
        <span>
          <i>{{ item.EnglishName }}</i
          >/USDT</span
        >
        <span
          :style="{
            color:
              item.Price - item.BasicPrice > 0
                ? basicConfig.RiseColour
                : basicConfig.FallColour
          }"
          >{{ item.Price }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    productNowInfo: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },

  methods: {
    handleClick(index) {
      this.$emit('change', index)
    }
  }
}
</script>

<style lang="less" scoped>
.leftInfo {
  height: 100%;
  background: #202833;
  font-weight: bold;
  .title {
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid rgb(18, 21, 28);
  }
  .usdt {
    color: #e143ac;
    padding: 10px 15px;
    font-size: 18px;
    border-bottom: 1px solid rgb(18, 21, 28);
  }
  .scroll {
    overflow-y: auto;
    height: calc(100% - 88px);
    font-size: 16px;
    .item {
      border-bottom: 1px solid rgb(18, 21, 28);
      padding: 0 10px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #7286a5;
      i {
        font-style: normal;
        color: #fff;
      }
    }
  }
}
</style>
